import { forwardRef } from 'react';

import { IconProps } from './IconProps';
import { IconSvg } from './IconSvg';

export const TextAltIcon = forwardRef<HTMLSpanElement, IconProps>(
  ({ color, ...rest }, ref) => {
    return (
      <IconSvg ref={ref} {...rest}>
        <path
          d="M21 3.6v16.8a.6.6 0 01-.6.6H3.6a.6.6 0 01-.6-.6V3.6a.6.6 0 01.6-.6h16.8a.6.6 0 01.6.6z"
          stroke={color || 'currentColor'}
          fill="none"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7 9V7h10v2M12 7v10m0 0h-2m2 0h2"
          fill="none"
          stroke={color || 'currentColor'}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </IconSvg>
    );
  }
);

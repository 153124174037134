import { ColorPalette } from '@komo-tech/core/models/ColorPalette';
import {
  createContext,
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useContext,
  useMemo,
  useState
} from 'react';

type ManageColorPaletteHandler = (value: ColorPalette) => void;

type ContextState = {
  colorPalettes: ColorPalette[];
  setColorPalettes: Dispatch<SetStateAction<ColorPalette[]>>;
  registerManageColorPalette: (handler: ManageColorPaletteHandler) => void;
  handleManageColorPalette?: ManageColorPaletteHandler;
};

interface Props {
  children: ReactNode;
  defaultPalettes?: ColorPalette[];
  onManageColorPalette?: ManageColorPaletteHandler;
}

const Context = createContext<ContextState>(null);

export const ColorPalettesProvider: FC<Props> = ({
  children,
  defaultPalettes,
  onManageColorPalette
}) => {
  const [colorPalettes, setColorPalettes] = useState<ColorPalette[]>(
    defaultPalettes || []
  );
  const [handleManageColorPalette, setOnManageColorPalette] =
    useState<ManageColorPaletteHandler>(onManageColorPalette);

  const providerValue = useMemo<ContextState>(
    () => ({
      colorPalettes,
      setColorPalettes,
      registerManageColorPalette: (h) => setOnManageColorPalette(() => h),
      handleManageColorPalette
    }),
    [colorPalettes, handleManageColorPalette]
  );

  return <Context.Provider value={providerValue}>{children}</Context.Provider>;
};

export const useColorPalettes = () => useContext(Context);

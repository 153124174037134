import {
  NumberInput as MantineNumberInput,
  NumberInputProps as MantineNumberInputProps
} from '@mantine/core';
import isNil from 'lodash/isNil';
import { forwardRef } from 'react';

import { FormInputWrapper, IAmFormInputRenderProps } from '../InputWrapper';

export interface FormNumberInputProps
  extends Omit<
      MantineNumberInputProps,
      'labelProps' | 'descriptionProps' | 'withAsterisk' | 'size'
    >,
    IAmFormInputRenderProps<HTMLInputElement> {
  placeholder?: string;
  /**
   * @deprecated use decimalScale & fixedDecimalScale instead
   */
  precision?: number;
}

export const FormNumberInput = forwardRef<
  HTMLInputElement,
  FormNumberInputProps
>(
  (
    {
      required,
      onFocus,
      selectAllTextOnFocus,
      autoFocus,
      descriptionProps,
      error,
      hideErrorMessage,
      inputContainer,
      showSkeleton,
      labelProps,
      size,
      precision,
      decimalScale: decimalScaleProp,
      fixedDecimalScale: fixedDecimalScaleProp,
      min,
      max,
      ...rest
    },
    forwardedRef
  ) => {
    let decimalScale = decimalScaleProp;
    let fixedDecimalScale = fixedDecimalScaleProp;
    if (!isNil(precision)) {
      decimalScale = precision;
      fixedDecimalScale = true;
    }
    return (
      <FormInputWrapper<HTMLInputElement>
        ref={forwardedRef}
        autoFocus={autoFocus}
        onFocus={onFocus}
        error={error}
        size={size}
        hideErrorMessage={hideErrorMessage}
        selectAllTextOnFocus={selectAllTextOnFocus}
        descriptionProps={descriptionProps}
        inputContainer={inputContainer}
        showSkeleton={showSkeleton}
        labelProps={labelProps}
      >
        {(p, ref) => (
          <MantineNumberInput
            min={min ?? undefined}
            max={max ?? undefined}
            ref={ref}
            withAsterisk={required}
            {...rest}
            {...p}
            decimalScale={decimalScale}
            fixedDecimalScale={fixedDecimalScale}
          />
        )}
      </FormInputWrapper>
    );
  }
);

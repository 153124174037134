import { ImageDataModel } from '@komo-tech/core/models/ImageDataModel';
import { useConstant } from '@komo-tech/ui/hooks/useConstant';
import { useEvent } from '@komo-tech/ui/hooks/useEvent';
import { ModalSkeleton } from '@komo-tech/ui/Modal/Skeleton';
import { useModal } from '@komo-tech/ui/Modal/useModal';
import dynamic from 'next/dynamic';
import { useRef } from 'react';

import { UploadTypes } from '@/common/models/UploadTypes';

import { UseImageModalOptions } from './ImageEditorModal.types';

const ImageEditorModal = dynamic(() => import('./ImageEditorModal'), {
  ssr: false,
  loading: () => (
    <ModalSkeleton
      withFooter={false}
      size={'Normal'}
      contentVariant="komo-loader"
    />
  )
});

export const useImageEditorModal = ({
  onChange,
  onClose,
  onSignAsync,
  size,
  canRemove,
  acceptTypes,
  ignoreSizeOnReadUrl,
  disabled,
  hiddenTabs,
  modalTitle
}: Omit<UseImageModalOptions, 'onUpdate'>) => {
  const valueRef = useRef<ImageDataModel>();
  const handleChange = useEvent(onChange);
  const handleClose = useEvent(onClose);
  const handleSignAsync = useEvent(onSignAsync);

  const [show, hide] = useModal(() => {
    return (
      <ImageEditorModal
        size={size}
        value={valueRef.current}
        canRemove={canRemove}
        modalTitle={modalTitle}
        onSignAsync={handleSignAsync}
        disabled={disabled}
        hiddenTabs={hiddenTabs}
        ignoreSizeOnReadUrl={ignoreSizeOnReadUrl}
        acceptTypes={
          acceptTypes || [
            UploadTypes.png,
            UploadTypes.jpeg,
            UploadTypes.gif,
            UploadTypes.webp
          ]
        }
        onClose={() => {
          handleClose();
          hide();
        }}
        onUpdate={handleChange}
        onChange={(x) => {
          handleChange(x);
          hide();
        }}
      />
    );
  }, [
    size,
    canRemove,
    modalTitle,
    disabled,
    hiddenTabs,
    ignoreSizeOnReadUrl,
    acceptTypes
  ]);

  return useConstant(() => ({
    show: (image?: ImageDataModel) => {
      valueRef.current = image;
      show();
    }
  }));
};

import { forwardRef } from 'react';

import { IconProps } from './IconProps';
import { getDefaultColor, IconSvg } from './IconSvg';

export const BorderStyleIcon = forwardRef<HTMLSpanElement, IconProps>(
  ({ color, ...rest }, ref) => {
    return (
      <IconSvg ref={ref} {...rest}>
        <path
          d="M16 2H8a6 6 0 00-6 6v8a6 6 0 006 6h8a6 6 0 006-6V8a6 6 0 00-6-6z"
          fill="none"
          stroke={color || getDefaultColor()}
          strokeWidth="1.5"
          strokeMiterlimit="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeDasharray="2 2"
        />
        <path
          d="M16 5H8a3 3 0 00-3 3v8a3 3 0 003 3h8a3 3 0 003-3V8a3 3 0 00-3-3z"
          fill="none"
          stroke={color || getDefaultColor()}
          strokeWidth="1.5"
          strokeMiterlimit="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </IconSvg>
    );
  }
);

import isNil from 'lodash/isNil';
import { FC, memo, ReactNode } from 'react';
import { createPortal } from 'react-dom';
import isEqual from 'react-fast-compare';

import { FCC } from '../../fcc';
import { useTheme } from '../../theme/useTheme';
import { Box } from '../Box';
import classes from './ElementHighlighterV2.module.css';

interface BaseProps {
  show: boolean;
  highlightColor?: string;
  showLabel?: boolean;
  borderSize?: number;
  labelContent?: ReactNode;
  dataAttributes?: Record<string, string>;
}

export interface HighlightBoundsDto {
  height: number;
  width: number;
  x: number;
  y: number;
}

export interface ElementHighlighterV2Props extends BaseProps {
  portal?: {
    element: HTMLElement;
    bounds: HighlightBoundsDto;
  };
  element: HighlightBoundsDto;
}

export const ElementHighlighterV2: FCC<ElementHighlighterV2Props> = ({
  portal,
  element,
  ...rest
}) => {
  return (
    <>
      {createPortal(
        <HighlightRender
          {...rest}
          y={(element.y || 0) - (portal?.bounds?.y || 0)}
          x={(element.x || 0) - (portal?.bounds?.x || 0)}
          height={element.height}
          width={element.width}
        />,
        portal?.element || document.body
      )}
    </>
  );
};

interface HighlightRenderProps extends HighlightBoundsDto, BaseProps {}

const HighlightRender: FC<HighlightRenderProps> = memo(
  ({
    show,
    borderSize,
    highlightColor,
    labelContent,
    showLabel,
    x,
    y,
    width,
    height,
    dataAttributes = {},
    ...rest
  }) => {
    const hasLabelContent = !!labelContent;
    const hasShowLabelOverride = !isNil(showLabel);
    const shouldShowLabel =
      hasLabelContent && hasShowLabelOverride ? showLabel : show;
    const theme = useTheme();
    return (
      <>
        {show && (
          <Box
            className={classes.highlighter}
            style={{
              outline: `${borderSize || 1}px solid ${highlightColor || theme.colors['komo-primary'][7]}`
            }}
            top={y}
            left={x}
            w={width}
            h={height}
            {...dataAttributes}
            {...rest}
          >
            {shouldShowLabel && (
              <Box
                className={classes.label}
                bg={highlightColor || theme.colors['komo-primary'][7]}
              >
                {labelContent}
              </Box>
            )}
          </Box>
        )}
      </>
    );
  },
  isEqual
);

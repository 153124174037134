import {
  ColorPickerProps as MantineColorPickerProps,
  Input as MantineInput
} from '@mantine/core';
import { useDidUpdate, useUncontrolled } from '@mantine/hooks';
import React, { FC, useEffect, useState } from 'react';

import { ActionButton } from '../../../ActionButton';
import { CloseIcon } from '../../../Icons/CloseIcon';
import { useColorPickerContext } from '../ColorPickerElement.context';
import { HsvaColor } from '../ColorPickerElement.types';
import { convertHsvaTo, isColorValid, parseColor } from '../converters';

interface Props {
  format: MantineColorPickerProps['format'];
  disabled?: boolean;
  value: string;
  onChangeEnd: (color: HsvaColor) => void;
  onClear: () => void;
  clearEnabled?: boolean;
  emptyColor?: string;
}

export const ColorPickerElementInput: FC<Props> = ({
  onChangeEnd,
  value: valueProp,
  format,
  disabled,
  clearEnabled,
  emptyColor,
  onClear
}) => {
  const { getStyles } = useColorPickerContext()!;

  const [lastValidValue, setLastValidValue] = useState('');
  const [value, setValue] = useUncontrolled({
    defaultValue: valueProp,
    finalValue: ''
  });

  useEffect(() => {
    if (isColorValid(valueProp)) {
      setValue(convertHsvaTo(format, parseColor(valueProp)));
    }
  }, [valueProp]);

  useDidUpdate(() => {
    if (isColorValid(value)) {
      setValue(convertHsvaTo(format, parseColor(value)));
    }
  }, [format]);

  return (
    <MantineInput<'input'>
      {...getStyles('input')}
      size={'xs'}
      disabled={disabled}
      onBlur={(event) => {
        const inputValue = event.currentTarget.value;
        if (!inputValue && clearEnabled) {
          onClear();
          return;
        }
        if (isColorValid(inputValue)) {
          onChangeEnd(parseColor(inputValue));
        } else {
          onChangeEnd(parseColor(lastValidValue));
          setValue(lastValidValue);
        }
      }}
      rightSectionPointerEvents="all"
      rightSection={
        clearEnabled && !!value ? (
          <ActionButton
            size="calc(var(--input-height) * 0.8)"
            variant="transparent"
            tooltip={{ label: 'Clear' }}
            onClick={() => {
              setValue('');
              onClear();
            }}
          >
            <CloseIcon />
          </ActionButton>
        ) : undefined
      }
      spellCheck={false}
      value={value}
      placeholder={emptyColor || 'Enter a HEX color'}
      onChange={(event) => {
        const inputValue = event.currentTarget.value;
        setValue(inputValue);
        if (isColorValid(inputValue)) {
          setLastValidValue(inputValue);
        }
      }}
    />
  );
};

import { forwardRef } from 'react';

import { IconProps } from './IconProps';
import { getDefaultColor, IconSvg } from './IconSvg';

export const DropletIcon = forwardRef<HTMLSpanElement, IconProps>(
  ({ color, color2, ...rest }, ref) => {
    return (
      <IconSvg ref={ref} {...rest}>
        <path
          d="M20 14c0-4.418-8-12-8-12S4 9.582 4 14a8 8 0 1016 0z"
          fill={color2 || 'transparent'}
          stroke={color || getDefaultColor()}
          strokeWidth="1.5"
        />
      </IconSvg>
    );
  }
);

import { OptionModel } from '@komo-tech/core/models/OptionModel';
import {
  __BaseInputProps as __MantineBaseInputProps,
  __CloseButtonProps as __MantineCloseButtonProps,
  __InputStylesNames as __MantineInputStylesNames,
  BoxProps as MantineBoxProps,
  Combobox as MantineCombobox,
  ComboboxLikeProps as MantineComboboxLikeProps,
  ComboboxLikeStylesNames as MantineComboboxLikeStylesNames,
  ElementProps as MantineElementProps,
  extractStyleProps as extractMantineStyleProps,
  Factory as MantineFactory,
  factory as mantineFactory,
  InputBase as MantineInputBase,
  InputPlaceholder as MantineInputPlaceholder,
  InputVariant as MantineInputVariant,
  MantineSize,
  Pill as MantinePill,
  PillsInput as MantinePillsInput,
  StylesApiProps as MantineStylesApiProps,
  useCombobox as useMantineCombobox,
  useProps as useMantineProps,
  useResolvedStylesApi as useMantineResolvedStylesApi,
  useStyles as useMantineStyles
} from '@mantine/core';
import { useId as useMantineId } from '@mantine/hooks';
import isNil from 'lodash/isNil';
import React, { ForwardedRef, useEffect, useMemo } from 'react';

import { useEvent } from '../../../../hooks/useEvent';
import { useUncontrolled } from '../../../../hooks/useUncontrolled';
import { SearchIcon } from '../../../Icons/SearchIcon';
import {
  customSelectSearchFilter,
  filterPickedMultiSelectValues,
  getOptionsLockup,
  groupOptions
} from './_functions';
import { FilterOptionsInput } from './_types';
import { MultiSelectPill, MultiSelectPillProps } from './MultiSelect.Pill';
import { SelectOptionsDropdown } from './Select.Dropdown';
import { SelectOptionProps, SelectOptions } from './Select.Option';

export type MultiSelectV2StylesNames =
  | __MantineInputStylesNames
  | MantineComboboxLikeStylesNames
  | 'pill'
  | 'pillsList'
  | 'inputField';

export interface MultiSelectProps<TOption extends OptionModel = OptionModel>
  extends MantineBoxProps,
    Omit<__MantineBaseInputProps, 'size'>,
    Omit<
      MantineComboboxLikeProps,
      'data' | 'selectFirstOptionOnChange' | 'filter'
    >,
    Omit<MantineStylesApiProps<MultiSelectFactory>, 'unstyled'>,
    Pick<
      MantineElementProps<'input'>,
      | 'name'
      | 'form'
      | 'id'
      | 'readOnly'
      | 'placeholder'
      | 'onKeyDown'
      | 'onFocus'
      | 'onBlur'
    >,
    Pick<MantineElementProps<'div'>, 'onClick'> {
  size?: MantineSize;
  /** The combobox options */
  data: TOption[];

  /** Controlled component value */
  value?: TOption['value'][];

  /** Uncontrolled component default value */
  defaultValue?: TOption['value'][];

  /** Called when value changes */
  onChange?: (value: TOption['value'][]) => void;

  /** Maximum number of values, `Infinity` by default */
  maxValues?: number;

  /** Determines whether the select should be searchable, `false` by default */
  searchable?: boolean;

  /** Message displayed when no option matched current search query, only applicable when `searchable` prop is set */
  nothingFoundMessage?: React.ReactNode;

  /** Controlled search value */
  searchValue?: string;

  /** Default search value */
  defaultSearchValue?: string;

  /** Called when search changes */
  onSearchChange?: (value: string) => void;

  /** Determines whether picked options should be removed from the options list, `false` by default */
  hidePickedOptions?: boolean;

  /** Determines whether the clear button should be displayed in the right section when the component has value, `false` by default */
  clearable?: boolean;

  /** Props passed down to the clear button */
  clearButtonProps?: __MantineCloseButtonProps & MantineElementProps<'button'>;

  /** Props passed down to the hidden input */
  hiddenInputProps?: React.ComponentPropsWithoutRef<'input'>;

  /** Divider used to separate values in the hidden input `value` attribute, `','` by default */
  hiddenInputValuesDivider?: string;

  itemComponent?: SelectOptionProps<TOption>['component'];

  valueComponent?: MultiSelectPillProps<TOption>['component'];

  filter?: FilterOptionsInput<TOption>['predicate'];
  filterProps?: Pick<FilterOptionsInput, 'splitWords' | 'ignoreCase'>;

  searchPlaceholder?: string;
}

export type MultiSelectFactory<TOption extends OptionModel = OptionModel> =
  MantineFactory<{
    props: MultiSelectProps<TOption>;
    ref: HTMLInputElement;
    stylesNames: MultiSelectV2StylesNames;
    variant: MantineInputVariant;
  }>;

const defaultProps: Partial<MultiSelectProps> = {
  maxValues: Infinity,
  hiddenInputValuesDivider: ',',
  searchPlaceholder: 'Search...',
  hidePickedOptions: true,
  leftSectionPointerEvents: 'none'
};

function filterValue(value: string[], data: OptionModel[]): string[] {
  if (!Array.isArray(value)) {
    return undefined;
  }

  if (!data || data.length === 0) {
    return [];
  }

  const flatData: string[] = data.map((item) => {
    if (typeof item === 'object') {
      return item.value;
    }
    return item;
  });
  return value.filter((val) => flatData.includes(val));
}

function _MultiSelect<TOption extends OptionModel = OptionModel>(
  _props: MultiSelectProps<TOption>,
  ref: ForwardedRef<MultiSelectFactory<TOption>['ref']>
) {
  const props = useMantineProps(
    'MultiSelect',
    defaultProps as any,
    _props
  ) as MultiSelectProps<TOption>;
  const {
    classNames,
    className,
    style,
    styles,
    vars,
    size,
    value,
    defaultValue,
    onChange,
    onKeyDown,
    variant,
    data = [],
    dropdownOpened,
    defaultDropdownOpened,
    onDropdownOpen,
    onDropdownClose,
    onOptionSubmit,
    comboboxProps: comboboxPropsProp,
    filter,
    filterProps,
    limit: limitProp,
    withScrollArea,
    maxDropdownHeight,
    searchValue,
    defaultSearchValue,
    onSearchChange,
    readOnly,
    disabled,
    onFocus,
    onBlur,
    radius,
    rightSection,
    rightSectionWidth,
    rightSectionPointerEvents,
    rightSectionProps,
    leftSection,
    leftSectionWidth,
    leftSectionPointerEvents,
    leftSectionProps,
    inputContainer,
    inputWrapperOrder,
    withAsterisk,
    labelProps,
    descriptionProps,
    errorProps,
    wrapperProps,
    description,
    label,
    error,
    maxValues,
    searchable: searchableProp,
    nothingFoundMessage,
    hidePickedOptions,
    withErrorStyles,
    name,
    form,
    id,
    clearable,
    clearButtonProps,
    hiddenInputProps,
    placeholder,
    hiddenInputValuesDivider,
    required,
    onClick,
    itemComponent,
    valueComponent,
    searchPlaceholder,
    ...others
  } = props;

  const _id = useMantineId(id);
  const parsedData = useMemo(() => groupOptions<TOption>(data), [data]);
  const optionsLockup = useMemo(
    () => getOptionsLockup(parsedData) as Record<string, TOption>,
    [parsedData]
  );

  //Turn on searchable if not set and have more that 10 items
  let searchable = searchableProp;
  if (isNil(searchable) && (data || []).length > 10) {
    searchable = true;
  }

  //Until we implement virtualization we need to limit the options to 100 max
  let limit = limitProp;
  if (data.length > 100 && isNil(limit)) {
    limit = 100;
  }

  const combobox = useMantineCombobox({
    opened: dropdownOpened,
    defaultOpened: defaultDropdownOpened,
    onDropdownOpen: () => {
      onDropdownOpen?.();
      if (searchable) {
        setTimeout(() => {
          combobox.focusSearchInput();
        }, 50);
      }
    },
    onDropdownClose: () => {
      onDropdownClose?.();
      combobox.resetSelectedOption();
      if (searchable) {
        setSearchValue('');
      }
    }
  });

  const { styleProps, rest } = extractMantineStyleProps(others);

  const [_value, setValue] = useUncontrolled({
    value: filterValue(value, data),
    defaultValue,
    finalValue: [],
    onChange
  });

  const [_searchValue, setSearchValue] = useUncontrolled({
    value: searchValue,
    defaultValue: defaultSearchValue,
    finalValue: '',
    onChange: onSearchChange
  });

  useEffect(() => {
    if (combobox.dropdownOpened) {
      combobox.selectFirstOption();
    }
  }, [_searchValue]);

  const getStyles = useMantineStyles<MultiSelectFactory<TOption>>({
    name: 'MultiSelect',
    classes: {} as any,
    props,
    classNames,
    styles
  });

  const { resolvedClassNames, resolvedStyles } = useMantineResolvedStylesApi<
    MultiSelectFactory<TOption>
  >({
    props,
    styles,
    classNames
  });

  const handleInputKeydown = useEvent<
    MantineElementProps<'input'>['onKeyDown']
  >((e) => {
    onKeyDown?.(e);
    if (
      e.key === 'Backspace' &&
      _searchValue.length === 0 &&
      _value.length > 0
    ) {
      setValue(_value.slice(0, _value.length - 1));
    }
  });

  const values = _value
    .filter((x) => !isNil(optionsLockup[x]))
    .map((item, index) => (
      <MultiSelectPill
        key={`${item}-${index}`}
        withRemoveButton={!readOnly}
        onRemove={() => setValue(_value.filter((i) => item !== i))}
        value={optionsLockup[item]}
        component={valueComponent}
        {...getStyles('pill')}
      >
        {optionsLockup[item]?.label || item}
      </MultiSelectPill>
    ));

  const clearButton = clearable &&
    _value.length > 0 &&
    !disabled &&
    !readOnly && (
      <MantineCombobox.ClearButton
        size={size as string}
        {...clearButtonProps}
        onClear={() => {
          setValue([]);
          setSearchValue('');
        }}
      />
    );

  const filteredData = filterPickedMultiSelectValues({
    data: parsedData,
    value: _value
  });

  const comboboxProps = comboboxPropsProp ?? {};
  if (isNil(comboboxProps.withinPortal)) {
    comboboxProps.withinPortal = true;
  }

  if (isNil(comboboxProps.transitionProps)) {
    comboboxProps.transitionProps = {
      transition: 'pop'
    };
  }
  if (comboboxProps.withinPortal && !comboboxProps.zIndex) {
    //fix for showing tooltip above modals
    comboboxProps.zIndex = 1100;
  }

  if (readOnly) {
    others['data-disabled'] = 'true';
    others['aria-readonly'] = 'true';
  }

  return (
    <>
      <MantineCombobox
        store={combobox}
        classNames={resolvedClassNames}
        styles={resolvedStyles}
        size={size}
        readOnly={readOnly}
        __staticSelector="MultiSelect"
        onOptionSubmit={(val) => {
          onOptionSubmit?.(val);
          setSearchValue('');
          combobox.updateSelectedOptionIndex('selected');

          if (_value.includes(optionsLockup[val].value)) {
            setValue(_value.filter((v) => v !== optionsLockup[val].value));
          } else if (_value.length < maxValues!) {
            setValue([..._value, optionsLockup[val].value]);
          }
        }}
        {...comboboxProps}
      >
        <MantineCombobox.DropdownTarget>
          <MantinePillsInput
            {...styleProps}
            __staticSelector="MultiSelect"
            classNames={resolvedClassNames}
            styles={resolvedStyles}
            size={size}
            className={className}
            style={style}
            variant={variant}
            disabled={disabled}
            radius={radius}
            rightSection={
              rightSection ||
              clearButton || (
                <MantineCombobox.Chevron size={size} error={error} />
              )
            }
            rightSectionPointerEvents={
              !!rightSectionPointerEvents
                ? rightSectionPointerEvents
                : clearButton
                  ? 'all'
                  : 'none'
            }
            rightSectionWidth={rightSectionWidth}
            rightSectionProps={rightSectionProps}
            leftSection={leftSection}
            leftSectionWidth={leftSectionWidth}
            leftSectionPointerEvents={leftSectionPointerEvents}
            leftSectionProps={leftSectionProps}
            inputContainer={inputContainer}
            inputWrapperOrder={inputWrapperOrder}
            withAsterisk={withAsterisk}
            labelProps={labelProps}
            descriptionProps={descriptionProps}
            errorProps={errorProps}
            wrapperProps={wrapperProps}
            description={description}
            label={label}
            error={error}
            multiline
            withErrorStyles={withErrorStyles}
            __stylesApiProps={{
              ...props,
              rightSectionPointerEvents:
                rightSectionPointerEvents || (clearButton ? 'all' : 'none'),
              multiline: true
            }}
            pointer
            onClick={(e) => {
              combobox.toggleDropdown();
              onClick?.(e);
            }}
            data-expanded={combobox.dropdownOpened || undefined}
            id={_id}
            required={required}
          >
            <MantinePill.Group disabled={disabled} {...getStyles('pillsList')}>
              {values.length > 0 ? (
                values
              ) : !!placeholder ? (
                <MantineInputPlaceholder>{placeholder}</MantineInputPlaceholder>
              ) : null}
              <MantineCombobox.EventsTarget>
                <MantinePillsInput.Field
                  {...rest}
                  ref={ref}
                  id={_id}
                  type={'hidden'}
                  {...getStyles('inputField')}
                  onFocus={onFocus}
                  onBlur={onBlur}
                  onKeyDown={handleInputKeydown}
                  disabled={disabled}
                  readOnly={readOnly || !searchable}
                  pointer
                />
              </MantineCombobox.EventsTarget>
            </MantinePill.Group>
          </MantinePillsInput>
        </MantineCombobox.DropdownTarget>

        <SelectOptionsDropdown
          data={hidePickedOptions ? filteredData : parsedData}
          hidden={readOnly || disabled}
          filter={
            !!filter ? customSelectSearchFilter(filter, filterProps) : undefined
          }
          limit={limit}
          hiddenWhenEmpty={!searchable || !nothingFoundMessage}
          withScrollArea={withScrollArea}
          maxDropdownHeight={maxDropdownHeight}
          filterOptions={searchable}
          nothingFoundMessage={nothingFoundMessage}
          labelId={`${_id}-label`}
          searchProps={
            searchable
              ? {
                  value: _searchValue,
                  leftSection: <SearchIcon />,
                  placeholder: searchPlaceholder,
                  onChange: (event) => {
                    setSearchValue(event.currentTarget.value);
                  }
                }
              : undefined
          }
        >
          {(_data) => (
            <SelectOptions
              data={_data}
              component={itemComponent}
              value={_value}
              size={size}
            />
          )}
        </SelectOptionsDropdown>
      </MantineCombobox>
      <input
        type="hidden"
        name={name}
        value={_value.join(hiddenInputValuesDivider)}
        form={form}
        disabled={disabled}
        {...hiddenInputProps}
      />
    </>
  );
}

const _FactoryMultiSelect = mantineFactory(_MultiSelect) as unknown as <
  TOption extends OptionModel = OptionModel
>(
  props: MultiSelectFactory<TOption>['props'] & {
    ref?: ForwardedRef<MultiSelectFactory<TOption>['ref']>;
  }
) => ReturnType<typeof _MultiSelect>;

const FactoryMultiSelect = _FactoryMultiSelect as any;

FactoryMultiSelect.classes = {
  ...MantineInputBase.classes,
  ...MantineCombobox.classes
};
FactoryMultiSelect.displayName = 'Select';

export const MultiSelectV2 = FactoryMultiSelect as typeof _FactoryMultiSelect;

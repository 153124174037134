import { FieldValues } from 'react-hook-form';

import { IAmFormHookField } from '../HookField';
import { FormColorInput, FormColorInputProps } from './FormColorInput';

export interface FormHookColorInputProps<
  TFieldValues extends FieldValues = FieldValues
> extends IAmFormHookField<TFieldValues>,
    Omit<
      FormColorInputProps,
      'required' | 'name' | 'value' | 'defaultValue' | 'error' | 'disabled'
    > {}

export function FormHookColorInput<
  TFieldValues extends FieldValues = FieldValues
>(props: FormHookColorInputProps<TFieldValues>) {
  const { hook, onBlur, onChange, ...rest } = props;
  return (
    <FormColorInput
      {...rest}
      required={hook.required}
      name={hook.field.name}
      ref={hook.field.ref}
      disabled={hook.disabled}
      onBlur={(e) => {
        hook.field.onBlur();
        onBlur?.(e);
      }}
      onChange={(v) => {
        hook.setValue(hook.field.name, v as any);
        onChange?.(v);
      }}
      value={hook.field.value}
      error={hook.showError ? hook.errorMessage : undefined}
    />
  );
}

'use client';
import {
  AlphaSlider as MantineAlphaSlider,
  Box as MantineBox,
  ColorSwatch as MantineColorSwatch,
  createVarsResolver as createMantineVarsResolver,
  factory as mantineFactory,
  getSize as getMantineSize,
  getSpacing as getMantineSpacing,
  HueSlider as MantineHueSlider,
  useProps as useMantineProps,
  useStyles as useMantineStyles
} from '@mantine/core';
import { useDidUpdate } from '@mantine/hooks';
import { useMemo, useRef, useState } from 'react';
import isEqual from 'react-fast-compare';

import { useUncontrolled } from '../../../hooks/useUncontrolled';
import { ActionButton } from '../../ActionButton';
import { useColorPalettes } from '../../ColorPalettes/ColorPalettesProvider';
import { Divider } from '../../Divider';
import { Group } from '../../Group';
import { SettingsIcon } from '../../Icons/SettingsIcon';
import { Stack } from '../../Stack';
import { Text } from '../../Text';
import { ColorPickerProvider } from './ColorPickerElement.context';
import classes from './ColorPickerElement.module.css';
import {
  ColorPickerElementFactory,
  ColorPickerElementProps,
  HsvaColor
} from './ColorPickerElement.types';
import { convertHsvaTo, isColorValid, parseColor } from './converters';
import { EyeDropperButton } from './EyeDropper';
import { ColorPickerElementInput as Input } from './Input';
import { Saturation } from './Saturation';
import { Swatches } from './Swatches/Swatches';

const defaultProps: Partial<ColorPickerElementProps> = {
  swatchesPerRow: 10,
  withPicker: true,
  focusable: true,
  withEyeDropper: true,
  format: 'hexa',
  size: 'sm',
  emptyColor: '#FFFFFF',
  __staticSelector: 'ColorPickerElement'
};

const varsResolver = createMantineVarsResolver<ColorPickerElementFactory>(
  (_, { size, swatchesPerRow }) => ({
    wrapper: {
      '--cp-preview-size': getMantineSize(size, 'cp-preview-size'),
      '--cp-width': getMantineSize(size, 'cp-width'),
      '--cp-body-spacing': getMantineSpacing(size),
      '--cp-swatch-size': `${100 / swatchesPerRow!}%`,
      '--cp-thumb-size': getMantineSize(size, 'cp-thumb-size'),
      '--cp-saturation-height': getMantineSize(size, 'cp-saturation-height'),
      '--cp-input-wrapper-gap': getMantineSpacing(size)
    }
  })
);

export const ColorPickerElement = mantineFactory<ColorPickerElementFactory>(
  (_props, ref) => {
    const props = useMantineProps('ColorPickerElement', defaultProps, _props);
    const {
      classNames,
      className,
      style,
      styles,
      unstyled,
      vars,
      format,
      value: valueProp,
      defaultValue,
      onChange,
      onChangeEnd,
      withPicker,
      size,
      saturationLabel,
      hueLabel,
      alphaLabel,
      focusable,
      palettes: palettesProp,
      swatchesPerRow,
      fullWidth,
      onColorSwatchClick,
      __staticSelector,
      withEyeDropper,
      emptyColor,
      clearEnabled,
      hidePalettes,
      ...others
    } = props;

    const getStyles = useMantineStyles<ColorPickerElementFactory>({
      name: __staticSelector!,
      props,
      classes,
      className,
      style,
      classNames,
      styles,
      unstyled,
      rootSelector: 'wrapper',
      vars,
      varsResolver
    });

    const formatRef = useRef(format);
    const valueRef = useRef<string>();
    const scrubTimeoutRef = useRef<number>(-1);
    const isScrubbingRef = useRef(false);
    const withAlpha =
      format === 'hexa' || format === 'rgba' || format === 'hsla';
    const cp = useColorPalettes();
    const { colorPalettes, handleManageColorPalette } = cp || {};
    const palettes = palettesProp || colorPalettes || [];
    const [value, setValue, controlled] = useUncontrolled({
      value: valueProp,
      defaultValue,
      finalValue: null,
      onChange
    });

    const parsedEmpty = useMemo(
      () => (!!emptyColor ? parseColor(emptyColor) : null),
      [emptyColor]
    );
    const [parsed, setParsed] = useState<HsvaColor>(
      parseColor(value || emptyColor)
    );

    const startScrubbing = () => {
      window.clearTimeout(scrubTimeoutRef.current);
      isScrubbingRef.current = true;
    };

    const stopScrubbing = () => {
      window.clearTimeout(scrubTimeoutRef.current);
      scrubTimeoutRef.current = window.setTimeout(() => {
        isScrubbingRef.current = false;
      }, 200);
    };

    const handleChange = (color: Partial<HsvaColor>) => {
      setParsed((current) => {
        const next = { ...current, ...color };
        if (
          current.a < 0.5 &&
          next.a < 0.5 &&
          current.a === next.a &&
          !!emptyColor &&
          isEqual(current, parsedEmpty)
        ) {
          next.a = 1;
        }

        valueRef.current = convertHsvaTo(formatRef.current!, next);
        return next;
      });

      setValue(valueRef.current!);
    };

    useDidUpdate(() => {
      const colorValue = isColorValid(value!) ? value : emptyColor;
      if (isColorValid(colorValue!) && !isScrubbingRef.current) {
        setParsed(parseColor(colorValue!));
      }
    }, [value]);

    useDidUpdate(() => {
      formatRef.current = format;
      setValue(convertHsvaTo(format!, parsed));
    }, [format]);

    const hasPalettes = (palettes || []).some(
      (p) => p.hasItems() || (p.canManage && !!handleManageColorPalette)
    );

    return (
      <ColorPickerProvider value={{ getStyles, unstyled }}>
        <MantineBox
          ref={ref}
          {...getStyles('wrapper')}
          size={size}
          mod={{ 'full-width': fullWidth }}
          {...others}
        >
          {withPicker && (
            <>
              <Saturation
                value={parsed}
                onChange={handleChange}
                onChangeEnd={({ s, v }) => {
                  onChangeEnd?.(
                    convertHsvaTo(formatRef.current!, {
                      ...parsed,
                      s: s!,
                      v: v!
                    })
                  );
                }}
                color={value || emptyColor}
                size={size!}
                focusable={focusable}
                saturationLabel={saturationLabel}
                onScrubStart={startScrubbing}
                onScrubEnd={stopScrubbing}
              />

              <div {...getStyles('body')}>
                <div {...getStyles('slidersWrapper')}>
                  <div {...getStyles('sliders')}>
                    <MantineHueSlider
                      value={parsed.h}
                      onChange={(h) => handleChange({ h })}
                      onChangeEnd={(h) =>
                        onChangeEnd?.(
                          convertHsvaTo(formatRef.current!, { ...parsed, h })
                        )
                      }
                      size={size}
                      focusable={focusable}
                      aria-label={hueLabel}
                      onScrubStart={startScrubbing}
                      onScrubEnd={stopScrubbing}
                    />

                    {withAlpha && (
                      <MantineAlphaSlider
                        value={parsed.a}
                        onChange={(a) => handleChange({ a })}
                        onChangeEnd={(a) => {
                          onChangeEnd?.(
                            convertHsvaTo(formatRef.current!, { ...parsed, a })
                          );
                        }}
                        size={size}
                        color={convertHsvaTo('hex', parsed)}
                        focusable={focusable}
                        aria-label={alphaLabel}
                        onScrubStart={startScrubbing}
                        onScrubEnd={stopScrubbing}
                      />
                    )}
                  </div>

                  {withAlpha && (
                    <MantineColorSwatch
                      color={value || emptyColor}
                      radius="sm"
                      size="var(--cp-preview-size)"
                      {...getStyles('preview')}
                    />
                  )}
                </div>

                <div {...getStyles('inputWrapper')}>
                  <Input
                    format={format}
                    value={value}
                    clearEnabled={clearEnabled}
                    emptyColor={emptyColor}
                    onClear={() => {
                      if (clearEnabled) {
                        if (emptyColor) {
                          setParsed(parseColor(emptyColor!));
                        }
                        onChangeEnd?.('');
                      }
                    }}
                    onChangeEnd={(c) => {
                      handleChange(c);
                      onChangeEnd?.(convertHsvaTo(formatRef.current, c));
                    }}
                  />
                  {withEyeDropper && (
                    <EyeDropperButton
                      onChange={(c) => {
                        handleChange(c);
                        onChangeEnd?.(convertHsvaTo(formatRef.current, c));
                      }}
                    />
                  )}
                </div>
              </div>
            </>
          )}

          {hasPalettes && !hidePalettes && (
            <Stack mt="0.5rem" gap="0.25rem">
              {palettes
                .filter(
                  (p) =>
                    p.hasItems() || (p.canManage && !!handleManageColorPalette)
                )
                .map((p) => (
                  <Stack gap="0.25rem" key={p.id.toString()}>
                    <Group align="center" justify="space-between" gap="0.5rem">
                      <Text size="xs">{p.label}</Text>
                      {p.canManage && !!handleManageColorPalette && (
                        <ActionButton
                          size="sm"
                          variant="subtle"
                          tooltip={{ label: `Manage ${p.label}` }}
                          onClick={() => handleManageColorPalette(p)}
                        >
                          <SettingsIcon />
                        </ActionButton>
                      )}
                    </Group>
                    <Divider />
                    <Swatches
                      data={p.items}
                      swatchesPerRow={swatchesPerRow}
                      focusable={focusable}
                      setValue={setValue}
                      onChangeEnd={(color) => {
                        const convertedColor = convertHsvaTo(
                          format!,
                          parseColor(color)
                        );
                        onColorSwatchClick?.(convertedColor);
                        onChangeEnd?.(convertedColor);
                        if (!controlled) {
                          setParsed(parseColor(color));
                        }
                      }}
                    />
                  </Stack>
                ))}
            </Stack>
          )}
        </MantineBox>
      </ColorPickerProvider>
    );
  }
);

ColorPickerElement.classes = classes;
ColorPickerElement.displayName = 'ColorPickerElement';

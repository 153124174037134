import { OptionModel } from '@komo-tech/core/models/OptionModel';
import {
  Combobox as MantineCombobox,
  ComboboxSearchProps as MantineComboboxSearchProps,
  MantineSize,
  ScrollArea as MantineScrollArea
} from '@mantine/core';
import { ReactNode } from 'react';

import classes from './_Components.module.css';
import {
  defaultOptionsFilter,
  isEmptyComboboxData,
  validateOptions
} from './_functions';
import { ComboboxParsedItem, OptionsData, OptionsFilter } from './_types';

export interface SelectOptionsDropdownProps<
  TOption extends OptionModel = OptionModel
> {
  size?: MantineSize;
  data: OptionsData<TOption>;
  filter?: OptionsFilter<TOption>;
  limit?: number;
  withScrollArea?: boolean;
  maxDropdownHeight?: number | string;
  hidden?: boolean;
  hiddenWhenEmpty?: boolean;
  filterOptions?: boolean;
  nothingFoundMessage?: ReactNode;
  labelId: string;
  topSection?: ReactNode;
  searchProps?: MantineComboboxSearchProps;
  children: (data: ComboboxParsedItem<TOption>[]) => ReactNode;
}

export function SelectOptionsDropdown<
  TOption extends OptionModel = OptionModel
>({
  data,
  hidden,
  hiddenWhenEmpty,
  filter,
  limit,
  maxDropdownHeight,
  withScrollArea = true,
  filterOptions = true,
  searchProps,
  nothingFoundMessage,
  labelId,
  topSection,
  children
}: SelectOptionsDropdownProps<TOption>) {
  validateOptions(data);

  const shouldFilter = typeof searchProps?.value === 'string';
  const filteredData = shouldFilter
    ? ((filter || defaultOptionsFilter)({
        data,
        search: filterOptions ? (searchProps.value as any) : '',
        limit: limit ?? Infinity
      }) as ComboboxParsedItem<TOption>[])
    : data;
  const hasAnyData = data.length > 0;
  const isEmpty = isEmptyComboboxData(filteredData);

  const hasSearch = !!searchProps;

  return (
    <MantineCombobox.Dropdown
      hidden={hidden || (hiddenWhenEmpty && !hasAnyData)}
    >
      {topSection}
      {hasSearch ? <MantineCombobox.Search {...searchProps} /> : null}
      <MantineCombobox.Options labelledBy={labelId}>
        {!isEmpty && (
          <>
            {withScrollArea ? (
              <MantineScrollArea.Autosize
                mah={maxDropdownHeight ?? 220}
                type="scroll"
                scrollbarSize="var(--_combobox-padding)"
                offsetScrollbars="y"
                className={classes.scrollArea}
              >
                {children(filteredData)}
              </MantineScrollArea.Autosize>
            ) : (
              children(filteredData)
            )}
          </>
        )}

        {isEmpty && nothingFoundMessage && (
          <MantineCombobox.Empty>{nothingFoundMessage}</MantineCombobox.Empty>
        )}
      </MantineCombobox.Options>
    </MantineCombobox.Dropdown>
  );
}
